<template>
  <div id="reserve">
    <div class="logo">
      <img src="../assets/image/book.png" alt="" />
      <div class="title">图书预约</div>
    </div>
    <div class="search">
      <van-search
        v-model="title"
        placeholder="请输入题名"
        show-action
        @search="onSearch"
      >
        <div slot="action" @click="onSearch">
          <van-button type="primary" style="height: 34px">搜索</van-button>
        </div>
      </van-search>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      v-if="list.length"
    >
      <van-cell v-for="item in list" :key="item.id">
        <div class="imgbox">
          <img src="../static/image/book2.png" alt="" />
        </div>
        <div class="infobox">
          <p>图书名：{{ item.title }}</p>
          <p>作者：{{ item.author }}</p>
          <p>索书号：{{ item.barcode }}</p>
          <p>条码号：{{ item.callnum }}</p>
          <p>
            <van-button
              size="small"
              type="info"
              style="width: 60%"
              @click="order(item.barcode)"
              >预约</van-button
            >
          </p>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import { order, searchBook, reserveList } from "@/api/goods.js";
export default {
  data() {
    return {
      title: "",
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    //查询
    onSearch() {
      if (!this.title) {
        this.$notify({
          message: "请输入题名",
          duration: 2000,
          background: "#f44",
        });
      } else {
        reserveList({
          bookName: this.title,
        }).then((res) => {
          this.list = res.data.data.records;
          this.finished = true;
        });
      }
    },
    //列表加载
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 1) {
          this.finished = true;
        }
      }, 500);
    },
    //预约
    order(barcode) {
      order({
        barcode,
      }).then((res) => {
        this.$toast({
          message: res.data.code == 200 ? "预约成功" : res.data.msg,
          type: res.data.code == 200 ? "success" : "fail",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#reserve {
  width: 100%;
  height: 100%;
  .logo {
    margin-top: 50px;
    width: 100%;
    height: 200px;
    img {
      width: 120px;
    }
    .title {
      text-align: center;
      width: 100%;
      font-size: 20px;
    }
  }
  .van-list {
    .van-cell {
      .imgbox {
        float: left;
        width: 40%;
        img {
          width: 92px;
          height: 92px;
        }
      }
      .infobox {
        float: left;
        width: 60%;
        p {
          line-height: 16px;
        }
      }
    }
  }
}
</style>